import React from 'react';
import './App.css';
import Home from './pages/Home';
import { BrowserRouter as Router, Route, Routes, } from 'react-router-dom';
import Header from './components/Header';
import { Contact } from './pages/Contact';
import Footer from './components/Footer';
import { About } from './pages/About';
import Product from './pages/Product';
import { Checkout } from './components/Checkout';
import { Payment } from './components/Payment';
import Secure from './pages/Secure';


const App = () => {
  return (
    <Router>
      <div className="app-container">
        <Header />
        <div className="main-content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path='/Secure' element={<Secure/>}/>
            <Route path="/about" element={<About />} />
            <Route path="/product" element={<Product />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/cart" element={<Checkout />} />
            <Route path="/addpayment" element={<Payment />} />

          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
