import React from "react";
import "./Cart.css";
import ProductImage from '../assets/productwatch.jpeg'
import ProductImage1 from '../assets/p2.jpeg'
import { useNavigate } from "react-router-dom";


export const Cart = () => {

    const navigate = useNavigate();

    const handleclick = () => {

        console.log('yes');
        navigate("/cart");

    }
    return (
        <>

            <div className="main-cont">
                <div id="webcrumbs" >
                    <div className=" w-[1000px] bg-neutral-50  shadow p-12">
                        <div className="flex space-x-8 p-4 items-center">
                            <div className="flex-1">
                                <h1 className="font-title text-2xl font-bold">At XDR Technologies & Services,</h1>
                                <p className="mt-4">
                                    We are dedicated to offering cutting-edge IT solutions combined with an unwavering commitment to innovation and safety. Our mission is to blend technology with practical solutions that address real-world challenges. As a forward-thinking company, we deliver comprehensive IT services that empower businesses to thrive in the digital landscape, while also prioritizing personal safety in today’s fast-paced world.
                                    One of our flagship products, Flare, is a revolutionary women’s safety watch designed to provide peace of mind and protection for women in various environments. We recognize the importance of safety, especially for women who face unique challenges in today’s society. The Flare safety watch was created to be a reliable, discreet, and stylish companion that seamlessly integrates advanced safety features with everyday wear.

                                </p>
                                <button className="mt-6 bg-primary-500 text-white rounded-md px-4 py-2 flex justify-center" onClick={() => handleclick()}>
                                    Buy Now <span className="material-symbols-outlined">arrow_forward</span>
                                </button>
                            </div>
                            <div className="flex-none relative -top-4">
                                <img src={ProductImage}
                                    className="w-[200px] h-[300px] rounded-md" alt="Developer" />
                            </div>
                            <div className="flex-none relative top-4">
                                <img src={ProductImage1}
                                    className="w-[200px] h-[300px] rounded-md" alt="Developer" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

