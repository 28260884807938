import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';


const Footer = () => {


    return (
        <footer className="footer">
            <div className="quick-links">
                <h3>Quick Links</h3>
                <ul>
                    <li><Link to="/about">Terms and Conditions</Link></li>
                    <li><Link to="/about">Shipping Policy</Link></li>
                    <li><Link to="/about">Return & Refund Policy</Link></li>
                    <li><Link to="/about">Privacy Policy</Link></li>
                </ul>
            </div>
            <div className="footer-bottom">
                <p>&copy; 2024 XDR Technologies & Services. All Rights Reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;
