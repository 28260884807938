import React, { useEffect } from 'react';
import ProductImage from '../assets/product image.jpg';
import ProductImage1 from '../assets/home1.jpg';
import "../components/Cart.css";
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';

const Home = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate()
  console.log();
  

  useEffect(() => {
    console.log('start 1');
    
    const id = searchParams.get('id')
    if (id) {
      alert(`id found${id}`)
      console.log('start2');
      
    }
  }, [searchParams])
  const handleclick = () => {
    navigate('/product')
  }


  return (
    <div className='diso'>
      <img src={ProductImage1} alt="Product" style={{}} />
      <img src={ProductImage} alt="Product" style={{}} />
      <div className="buttonOwn" >
        <button className='txt' onClick={() => handleclick()}>
          Get Product <span className="material-symbols-outlined">arrow_forward</span>
        </button>
      </div>
    </div>
  );
};

export default Home;
