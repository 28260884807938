import React, { useEffect, useState } from 'react'
import { generateTransactionId, generateUserId } from '../utils/paymentTxnid';
import axios from 'axios';
const urlpro = 'https://us-central1-xdr-tech-site.cloudfunctions.net/xdrweb';
function Secure() {
    const [transactionId, setTransactionId] = useState('');
    const [merchantUserId, setMerchantUserId] = useState('');
    const [formData, setFormData] = useState({
        EnterAmount: '',
        name: '',
        email: '',
        mobile: '',
        address: '',
        city: '',
        postalCode: '',
    });
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setTransactionId(generateTransactionId());
        setMerchantUserId(generateUserId());
    }, []);


    const userData = {
        ...formData,
        userId: merchantUserId,
        TxnId: transactionId,
    };

    const storeData = async () => {
        try {
            const response = await axios.post(`${urlpro}/userPayApi`, { userData });
            console.log('Data stored successfully:', response);
        } catch (error) {
            console.error('Error storing data:', error);
            throw new Error('Unable to store user data.');
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        console.log(name, value);


        if (name === 'EnterAmount') {
            if (value.length < 7) {
                setFormData({
                    ...formData,
                    [name]: value.slice(0, 7),
                });

            }
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });

        }

    };


    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        try {
            await storeData();
            await handlePayment();

            setFormData({ EnterAmount: '', name: '', email: '', mobile: '', address: '', city: '', postalCode: '' });
        } catch (error) {
            console.error('An error occurred during the submission process', error);
        } finally {
            setLoading(false);  
        }
    };

    const handlebutonclick = (event) => {
        const form = document.getElementById("myForm");
        if (form.checkValidity()) {
            handleSubmit(event);
        } else {
            form.reportValidity();
        }
    };


    const handlePayment = async () => {
        const data = {
            amount: formData?.EnterAmount,
            transactionId,
            merchantUserId,
            mobileNumber: formData?.mobile,
        };

        // console.log("Payment Data:", data);

        try {
            const response = await axios.post(`${urlpro}/phonepePayment `, data);
            console.log("Response Data from payment:", response);

            if (response.data?.data?.instrumentResponse?.redirectInfo?.url) {
                window.location.href = response.data.data.instrumentResponse.redirectInfo.url;
            } else {
                console.error("Payment initiation failed. Try again.");
            }
        } catch (error) {
            console.error('Error initiating payment:', error);
            throw new Error('Payment initiation failed.');
        }
    };

    return (
        <div className="main-cont">
            <div id="webcrumbs">
                <div className="w-[800px] min-h-[600px] bg-neutral-50 rounded-lg shadow-md p-8">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div className="bg-neutral-50 rounded-md p-4 shadow-sm">
                            <h2 className="font-title text-xl mb-4">Payment Information</h2>
                            <form onSubmit={handleSubmit} id="myForm">
                                <label className="block mb-2">
                                    Payment:
                                    <input
                                        type="number"
                                        name="EnterAmount"
                                        value={formData.EnterAmount}
                                        onChange={handleChange}
                                        className="border rounded-md w-full p-2 mt-1"
                                        required
                                    />
                                </label>
                                <h2 className="font-title text-xl mt-4 mb-4">Billing Information</h2>
                                <label className="block mb-2">
                                    Name:
                                    <input
                                        type="text"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        className="border rounded-md w-full p-2 mt-1"
                                        required
                                    />
                                </label>
                                <label className="block mb-2">
                                    Email:
                                    <input
                                        type="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        className="border rounded-md w-full p-2 mt-1"
                                        required
                                    />
                                </label>
                                <label className="block mb-2">
                                    Mobile Number:
                                    <input
                                        type="tel"
                                        name="mobile"
                                        value={formData.mobile}
                                        onChange={handleChange}
                                        className="border rounded-md w-full p-2 mt-1"
                                        required
                                    />
                                </label>
                                <label className="block mb-2">
                                    Address:
                                    <input
                                        type="text"
                                        name="address"
                                        value={formData.address}
                                        onChange={handleChange}
                                        className="border rounded-md w-full p-2 mt-1"
                                        required
                                    />
                                </label>
                                <label className="block mb-2">
                                    City:
                                    <input
                                        type="text"
                                        name="city"
                                        value={formData.city}
                                        onChange={handleChange}
                                        className="border rounded-md w-full p-2 mt-1"
                                        required
                                    />
                                </label>
                                <label className="block mb-2">
                                    Postal Code:
                                    <input
                                        type="text"
                                        name="postalCode"
                                        value={formData.postalCode}
                                        onChange={handleChange}
                                        className="border rounded-md w-full p-2 mt-1"
                                        required
                                    />
                                </label>
                            </form>
                        </div>
                    </div>


                    {loading ? (
                        <div className="loader w-[220px] h-[50px] mt-6 flex items-center justify-center">
                            <span className="spinner-border text-primary red">loading...</span> 
                        </div>
                    ) : (
                        <button
                            onClick={handlebutonclick}
                            className="w-[220px] h-[50px] bg-primary rounded-full mt-6"
                            disabled={loading}  
                        >
                            Proceed to Pay
                        </button>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Secure