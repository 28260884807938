import React from "react";
import "../pagecss/About.css";

export const About = () => {
	return (
		<div id="webcrumbs">
			<div className=" min-h-[800px] shadow  bg-neutral-50 overflow-hidden">
				<div className="relative">
					<div className="h-72 bg-primary">
						<div className="flex flex-col justify-center items-center h-full bg-black bg-opacity-20">
							<h1 className="font-title text-2xl">About Us</h1>
							<p className="max-w-md text-center">
								Learn more about XDR Technologies & Services's mission, values and our commitment to excellence in every aspect of our services.
							</p>
						</div>
					</div>

					<div className="absolute top-40 w-full flex justify-center">
						<div className="bg-neutral-50 p-8 space-y-10  shadow w-[90%] max-w-[600px] mt-16">
							<div className="space-y-6">
								<h2 className="font-title text-lg">Our Commitment</h2>
								<p>
									At XDR Technologies & Services's, we strive to push the boundaries of exceptional service in the tech realm.
									We believe in innovation, dedication to customer service, and ensuring we meet your toughest challenges with effective solutions.
								</p>

								<details className="space-y-4">
									<summary className="font-bold cursor-pointer">Terms and Conditions</summary>
									<p className="text-sm  h-30 ">
										Welcome to XDR Technologies & Services's. By using our Women’s Safety Watch and related services, you agree to the following terms and conditions.
										1. Use of the Product
										- The Women’s Safety Watch is intended for personal use to enhance women’s safety.
										- Users must ensure the watch is used in accordance with local laws and regulations.
										2. User Responsibilities
										- Provide accurate and up-to-date information during registration.
										- Use the product responsibly and not for any unlawful activities.
										- Maintain the confidentiality of your account details.
										3. Intellectual Property
										All content, logos, and product designs are the property of XDR Technologies & Services's. You may not copy, distribute, or create derivative works without prior permission.
										4. Limitation of Liability
										XDR Technologies & Services's is not liable for any direct, indirect, incidental, or consequential damages arising from the use or inability to use the product.
										5. Governing Law
										These Terms and Conditions are governed by the laws of [State/Country]. Any disputes will be resolved in the courts of [State/Country].
										6. Changes to Terms & Conditions
										We may modify these terms at any time. The latest version will be posted on our website.
										7. Contact Us
										For any queries regarding these Terms and Conditions, please contact us at contact@xdrtech.com
									</p>
								</details>

								<details className="space-y-4">
									<summary className="font-bold cursor-pointer">Privacy Policy</summary>
									<p className="text-sm">
										XDR Technologies Privacy Policy
										1. We value your privacy: We collect personal, device, and health data (when using safety features) to provide, improve, and secure our Women's Safety Watch.
										2. Usage: We use your data to deliver the watch's functionalities, send safety alerts, provide customer support, and comply with legal requirements.3. Sharing: We might share data with service providers and when legally required.4. Security: We take security seriously, but no internet transmission is 100% secure.5. Your Rights: You can access, update, delete, or restrict your data and opt out of marketing communications.
										6. Updates: This policy may be updated, so please check our website.
										7. Contact Us: Questions? Contact us at contact@xdrtech.com
									</p>
								</details>

								<details className="space-y-4">
									<summary className="font-bold cursor-pointer"> Return & Refund Policy</summary>
									<p className="text-sm">
										1. Once a purchase is made for the Women’s Safety Watch, it is non-refundable.
										- Exceptions may be made for defective products, in which case, you can request a replacement within [7] days of purchase.
										2. No Cancellation Policy
										- Orders for the Women’s Safety Watch cannot be canceled once they have been processed and shipped.
										3. Product Replacement
										- If you receive a defective product, contact us within [7] days for a replacement.
										- Replacement requests are subject to approval after reviewing the nature of the defect.
										4. Contact Us
										If you have any concerns or questions about our No Refund/Cancellation Policy, please contact us at [contact@xdrtech.com](mailto:contact@xdrtech.com)
									</p>
								</details>

								<details className="space-y-4">
									<summary className="font-bold cursor-pointer">Shipping Policy</summary>
									<ol className="text-sm list-decimal pl-5 space-y-2">
										<li>Standard Shipping: Free on all orders. Expected delivery in 5-7 business days.</li>
										<li>Express Shipping: Available for an extra charge, with delivery in 2-3 business days.</li>
										<li>Order Processing: All orders are processed within 1-2 business days.</li>
										<li>Tracking Information: A tracking number will be sent via email once your order is dispatched.</li>
										<li>Replacement Policy: If your Flare Women Safety Watch arrives damaged or defective, we offer a hassle-free replacement
											within 30 days of delivery. Please contact our support team with your order details to initiate a replacement.</li>
									</ol>
								</details>

							</div>

							<div className="flex justify-end space-x-3 mt-8">
								{/* <details className="relative">
    	                        <summary className="cursor-pointer font-title underline z-10 relative">Quick Links</summary>
    	                        <div className="absolute left-0 top-full mt-2 bg-neutral-50 w-[150px] z-20 rounded-md shadow-lg border">
    	                            <ul className="py-2 flex flex-col gap-2">
    	                                <li><a href="/" className="hover:underline">Home</a></li>
    	                                <li><a href="/" className="hover:underline">Support</a></li>
    	                                <li><a href="/" className="hover:underline">Services</a></li>
    	                            </ul>
    	                        </div>
    	                    </details> */}
								<a href="mailto:contact@xdrtech.com">
									<button className="bg-primary rounded-md h-[40px] w-[150px]">Contact Us</button>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

