export const generateTransactionId = () => {
    const TXN = `TXN${Date.now()}_${Math.floor(Math.random() * 10000)}`
    console.log(`Generated TransactionId ID: ${TXN}`);
    return TXN;
};

let lastUserId = 0; 

export const generateUserId = () => {
    lastUserId++; 
    const userId = `xdrtect${String(lastUserId).padStart(4, '0')}`;
    console.log(`Generated User ID: ${userId}`);
    return userId;
};

