import React, { useEffect, useState } from "react";
import './Payment.css';
import { useLocation } from "react-router-dom";
import axios from "axios";
import { generateTransactionId, generateUserId } from "../utils/paymentTxnid";

const urlpro = 'https://us-central1-xdr-tech-site.cloudfunctions.net/xdrweb';

export const Payment = () => {
  const location = useLocation();
  const { totalPrice, count } = location.state || {};

  const [transactionId, setTransactionId] = useState('');
  const [merchantUserId, setMerchantUserId] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    address: '',
    city: '',
    postalCode: '',
  });
  const [loading, setLoading] = useState(false)
  // Generate Transaction ID and Merchant User ID only once on component mount
  useEffect(() => {
    setTransactionId(generateTransactionId());
    setMerchantUserId(generateUserId());
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const userData = {
    ...formData,
    totalPrice: totalPrice,
    userId: merchantUserId,
    TxnId: transactionId,
  };

  const storeData = async () => {
    try {
      const response = await axios.post(`${urlpro}/userApi`, { userData });
      console.log('Data stored successfully:', response);
    } catch (error) {
      console.error('Error storing data:', error);
      throw new Error('Unable to store user data.');
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      await storeData();
      await handlePayment();

      setFormData({ name: '', email: '', mobile: '', address: '', city: '', postalCode: '' });
    } catch (error) {
      console.error('An error occurred during the submission process', error);
    } finally {
      setLoading(false)
    }
  };

  const handlebutonclick = (event) => {
    const form = document.getElementById("myForm");
    if (form.checkValidity()) {
      handleSubmit(event);
    } else {
      form.reportValidity();
    }
  };

  const handlePayment = async () => {
    const data = {
      amount: totalPrice,
      transactionId,
      merchantUserId,
      mobileNumber: formData?.mobile,
    };

    // console.log("Payment Data:", data);

    try {
      const response = await axios.post(`${urlpro}/phonepePayment `, data);
      console.log("Response Data from payment:", response);

      if (response.data?.data?.instrumentResponse?.redirectInfo?.url) {
        window.location.href = response.data.data.instrumentResponse.redirectInfo.url;
      } else {
        console.error("Payment initiation failed. Try again.");
      }
    } catch (error) {
      console.error('Error initiating payment:', error);
      throw new Error('Payment initiation failed.');
    }
  };

  return (
    <div className="main-cont">
      <div id="webcrumbs">
        <div className="w-[800px] min-h-[600px] bg-neutral-50 rounded-lg shadow-md p-8">
          <h1 className="font-title text-2xl mb-6">Checkout</h1>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="bg-neutral-50 rounded-md p-4 shadow-sm">
              <h2 className="font-title text-xl mb-4">Billing Information</h2>
              <form onSubmit={handleSubmit} id="myForm">
                <label className="block mb-2">
                  Name:
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="border rounded-md w-full p-2 mt-1"
                    required
                  />
                </label>
                <label className="block mb-2">
                  Email:
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="border rounded-md w-full p-2 mt-1"
                    required
                  />
                </label>
                <label className="block mb-2">
                  Mobile Number:
                  <input
                    type="tel"
                    name="mobile"
                    value={formData.mobile}
                    onChange={handleChange}
                    className="border rounded-md w-full p-2 mt-1"
                    required
                  />
                </label>
                <label className="block mb-2">
                  Address:
                  <input
                    type="text"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                    className="border rounded-md w-full p-2 mt-1"
                    required
                  />
                </label>
                <label className="block mb-2">
                  City:
                  <input
                    type="text"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                    className="border rounded-md w-full p-2 mt-1"
                    required
                  />
                </label>
                <label className="block mb-2">
                  Postal Code:
                  <input
                    type="text"
                    name="postalCode"
                    value={formData.postalCode}
                    onChange={handleChange}
                    className="border rounded-md w-full p-2 mt-1"
                    required
                  />
                </label>
              </form>
            </div>
          </div>

          <div className="bg-neutral-50 rounded-md p-4 mt-6 shadow-sm">
            <h2 className="font-title text-xl mb-4">Order Summary</h2>
            <ul className="list-disc pl-5">
              <li>Flare Women Safety Watch X {count} - Rs.{totalPrice}/-</li>
              <li>Shipping - Free</li>
            </ul>
            <div className="flex justify-between font-bold mt-4 w-[220px]">
              <span>Total:-</span>
              <span>Rs.{totalPrice}/-</span>
            </div>
          </div>

          {loading ? (
            <div className="loader w-[220px] h-[50px] mt-6 flex items-center justify-center">
              <span className="spinner-border text-primary red">loading...</span>
            </div>
          ) : (
            <button
              onClick={handlebutonclick}
              className="w-[220px] h-[50px] bg-primary rounded-full mt-6"
              disabled={loading}
            >
              Proceed to Checkout
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
