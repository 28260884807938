import React, { useState } from "react";
import './Checkout.css';
import ProductImage from '../assets/productwatch.jpeg'
import { useNavigate } from "react-router-dom";


export const Checkout = ({ cart, setCart }) => {

	const navigate = useNavigate();

	const [count, setCount] = useState(1)
	const unitPrice = 2000;
	const [totalPrice, setTotalPrice] = useState(unitPrice)



	const handleQuantityChange = (newQuantity) => {
		setCount(newQuantity);
		setTotalPrice(newQuantity * unitPrice);
	};

	const handlepay = () => {
		console.log('yes');
		navigate('/addpayment', { state: { totalPrice, count } });
	}



	const handleclick = () => {
		console.log('dummy');
		navigate('/product');
	}



	return (
		// <div className="main-cont">
		<div id="webcrumbs">
			<div className="w-[1000px] bg-neutral-50 rounded-lg shadow p-12">
				<h1 className="font-title text-3xl font-bold mb-8">Your Cart</h1>
				<div className="flex gap-8 p-4 items-start">
					<div className="flex-none w-[200px] h-[300px]">
						<img
							src={ProductImage}
							className="object-cover w-[200px] h-[300px] rounded-md"
							alt="Flare Product"
						/>
					</div>
					<div className="flex-1">
						<h2 className="text-xl font-bold">Flare Women’s Safety Watch</h2>
						<p className="mt-4">Flare is a revolutionary women’s safety watch designed to offer peace of mind and protection in various environments.</p>
						<div className="flex items-center mt-6 gap-4">

							<div className="flex items-center gap-2">
								<button onClick={() => handleQuantityChange(count > 1 ? count - 1 : 1)}
									className="w-[32px] h-[32px] bg-primary-500 text-white rounded-full flex justify-center items-center">
									<span className="material-symbols-outlined">remove</span>
								</button>
								<span className="px-4 py-2 bg-neutral-100 rounded-md">{count}</span>
								<button onClick={() => handleQuantityChange(count + 1)}
									className="w-[32px] h-[32px] bg-primary-500 text-white rounded-full flex justify-center items-center">
									<span className="material-symbols-outlined">add</span>
								</button>
							</div>

							<p className="text-xl font-semibold">Rs.{totalPrice}/-</p>
						</div>


						<button className="mt-4 bg-primary-500 text-white rounded-md px-4 py-2 flex justify-center items-center" onClick={() => handleclick()}>
							Remove Item <span className="material-symbols-outlined ml-2">delete</span>
						</button>
					</div>
				</div>


				<div className="flex justify-between items-center mt-12">
					<div>
						<p className="text-xl font-semibold">Total:</p>
						<p className="text-2xl font-bold">Rs.{totalPrice}/- </p>
					</div>
					<button className="bg-primary-500 text-white rounded-md px-6 py-2 flex justify-center items-center" onClick={() => handlepay()}>
						Proceed to Checkout <span className="material-symbols-outlined ml-2">arrow_forward</span>
					</button>
				</div>
			</div>
		</div>
		// </div>
	)
}

