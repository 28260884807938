import React, { useState } from "react";
import '../pagecss/Contact.css';
import axios from "axios";

const urlpro = 'https://us-central1-xdr-tech-site.cloudfunctions.net/xdrweb';

export const Contact = () => {
	const [formData, setFormData] = useState({
		firstName: '',
		lastName: '',
		email: '',
		phone: '',
		message: '',
	});

	const [errors, setErrors] = useState({});
	const [isChecked, setIsChecked] = useState(false);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};

	const handleCheckboxChange = (e) => {
		setIsChecked(e.target.checked);
	};

	const validate = () => {
		let validationErrors = {};
		if (!formData.firstName) {
			validationErrors.firstName = 'First name is required.';
		}
		if (!formData.lastName) {
			validationErrors.lastName = 'Last name is required.';
		}
		if (!formData.email) {
			validationErrors.email = 'Email is required.';
		} else if (!/\S+@\S+\.\S+/.test(formData.email)) {
			validationErrors.email = 'Email address is invalid.';
		}
		if (!formData.phone) {
			validationErrors.phone = 'Phone number is required.';
		} else if (!/^\+\d{2} \d{5} \d{5}$/.test(formData.phone)) {
			validationErrors.phone = 'Phone number format is invalid. Use +91 12345 67890.';
		}
		if (!formData.message) {
			validationErrors.message = 'Message is required.';
		}
		if (!isChecked) {
			validationErrors.checkbox = 'You must agree to the Terms of Service and Privacy Statement.';
		}
		return validationErrors;
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const validationErrors = validate();
		setErrors(validationErrors);


		if (Object.keys(validationErrors).length === 0) {
			try {

				const res = await axios.post(`${urlpro}/ContactLead`, { formData })
				console.log(res.data, 'from contact api');

			} catch (error) {
				console.log(error, 'form contact api');

			}

		}
	};

	return (
		<div id="webcrumbs">
			<div className="min-h-[800px] shadow bg-neutral-50 overflow-hidden">
				<div className="relative">
					<div className="h-72 bg-primary">
						<div className="flex flex-col justify-center items-center h-full bg-black bg-opacity-20">
							<h1 className="text-white text-2xl font-title">Contact us</h1>
							<p className="text-white max-w-md text-center">
								Get in touch with our XDR Tech team to see how we can assist you with your toughest challenges.
							</p>
						</div>
					</div>
					<div className="absolute top-40 w-full flex justify-center">
						<div className="bg-neutral-50 p-8 space-y-10 shadow w-[90%] max-w-[600px] mt-16">
							<form className="space-y-6" onSubmit={handleSubmit}>
								<div className="flex space-x-4">
									<div className="flex flex-col w-1/2 space-y-2">
										<label>First name</label>
										<input
											type="text"
											name="firstName"
											placeholder="Annie"
											className="p-2 border rounded-md"
											value={formData.firstName}
											onChange={handleChange}
										/>
										{errors.firstName && <p className="text-red-600">{errors.firstName}</p>}
									</div>
									<div className="flex flex-col w-1/2 space-y-2">
										<label>Last name</label>
										<input
											type="text"
											name="lastName"
											placeholder="Akshaya"
											className="p-2 border rounded-md"
											value={formData.lastName}
											onChange={handleChange}
										/>
										{errors.lastName && <p className="text-red-600">{errors.lastName}</p>}
									</div>
								</div>
								<div className="flex space-x-4">
									<div className="flex flex-col w-1/2 space-y-2">
										<label>Email</label>
										<input
											type="email"
											name="email"
											placeholder="name@example.com"
											className="p-2 border rounded-md"
											value={formData.email}
											onChange={handleChange}
										/>
										{errors.email && <p className="text-red-600">{errors.email}</p>}
									</div>
									<div className="flex flex-col w-1/2 space-y-2">
										<label>Phone number</label>
										<input
											type="text"
											name="phone"
											placeholder="+91 92345 67890"
											className="p-2 border rounded-md"
											value={formData.phone}
											onChange={handleChange}
										/>
										{errors.phone && <p className="text-red-600">{errors.phone}</p>}
									</div>
								</div>
								<div className="flex flex-col space-y-2">
									<label>Your message</label>
									<textarea
										rows="4"
										name="message"
										className="p-2 border rounded-md"
										value={formData.message}
										onChange={handleChange}
									></textarea>
									{errors.message && <p className="text-red-600">{errors.message}</p>}
								</div>
								<div className="flex items-start space-x-3">
									<input
										type="checkbox"
										className="mt-1"
										checked={isChecked}
										onChange={handleCheckboxChange}
									/>
									{errors.checkbox && <p className="text-red-600">{errors.checkbox}</p>}
									<p className="text-sm">
										By submitting this form, you confirm that you have read and agree to our
										<a href="/about" className="text-blue-600 underline"> Terms of Service</a> and
										<a href="/about" className="text-blue-600 underline"> Privacy Statement</a>
									</p>
								</div>

								<div className="flex justify-end">
									<button type="submit" className="px-4 py-2 bg-primary text-white rounded-md shadow">Send message</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
