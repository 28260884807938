import React from 'react'
import '../pagecss/Contact.css'
import { Cart } from '../components/Cart'
import ProductImage from '../assets/product image.jpg';





function Product() {
    return (
        <>
            <div id="webcrumbs">
                <img src={ProductImage} alt="Product" style={{}} />
                <Cart />
            </div>
        </>
    )
}

export default Product